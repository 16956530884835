export const defaultState = { lang: 'hu', isNight: true }
export function langReducer(state, action) {
  switch (action.type) {
    case 'hu':
      return { lang: 'hu' }
    case 'en':
      return { lang: 'en' }
    default:
      return state
  }
}
export function isNightReducer(state, action) {
  switch (action.type) {
    case 'day':
      return { isNight: false }
    case 'night':
      return { isNight: true }
    default:
      return state
  }
}
