import React, { createContext, useReducer, useEffect } from 'react'

import { langReducer, isNightReducer } from './reducer'
export const LangContext = createContext()
export const isNightContext = createContext()
const LangerProvider = ({ children }) => {
  let initialState = { lang: 'hu' }
  let initialState2 = { isNight: true }
  if (typeof window !== 'undefined') {
    const userLang = navigator.language || navigator.userLanguage
    if (userLang.slice(0, 2) === 'hu' || userLang.slice(0, 2) === 'en') {
      initialState = { lang: userLang.slice(0, 2) }
    }
    if (
      (localStorage.getItem('lang') !== null &&
        localStorage.getItem('lang') === 'hu') ||
      (localStorage.getItem('lang') !== null &&
        localStorage.getItem('lang') === 'en')
    ) {
      initialState = { lang: localStorage.getItem('lang') }
    }
    if (sessionStorage.getItem('isNight') !== null) {
      initialState2 = { isNight: sessionStorage.getItem('isNight') === 'true' }
    }
  }
  const [state, dispatch] = useReducer(langReducer, initialState)
  const [state2, dispatch2] = useReducer(isNightReducer, initialState2)
  useEffect(() => {
    localStorage.setItem('lang', state.lang)
  }, [state.lang])
  useEffect(() => {
    sessionStorage.setItem('isNight', state2.isNight)
  }, [state2.isNight])

  return (
    <LangContext.Provider value={{ state, dispatch, state2, dispatch2 }}>
      {children}
    </LangContext.Provider>
  )
}

export default LangerProvider
